import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMensagensExtensao, enviarMensagemExtensao } from './OrdersTab';
import { sakBaseUrl } from '../../../../utils/usefulFunctions';

export default function TemplatesTab () {
  const { t } = useTranslation();

  const [previewMsg, setPreviewMsg] = useState(null);
  const messagesContainerRef = useRef(null);

  const { data: messages_data, isLoading: messages_isLoading } = useMensagensExtensao();

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <div className="order_section">
            <h4 className="sak--ext-title d-inline-flex">
              <i className="fe fe-message-square mr-3"/> { t('Mensagens rápidas') }
            </h4>
          </div>
          <div ref={messagesContainerRef} className="d-flex mt-4 px-3" style={{ flexWrap: 'wrap', gap: '.5rem', alignItems: 'center' }}>
            {
              messages_isLoading
                ? (
                  <span>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">{t('Carregando...')}</span>
                    </div>
                  </span>)
                : (
                  <>
                    {
                      messages_data?.mensagens?.map(msg => (
                        <span className='sIconWhats'>
                          <span
                            className="whatsLink"
                            onMouseOver={() => setPreviewMsg({ body: msg.texto, title: msg.nome })}
                            onMouseOut={() => setPreviewMsg(null)}
                            onClick={() => enviarMensagemExtensao(msg.id, 0)}
                          >
                            <i className='fab fa-whatsapp'/>
                          </span>
                        </span>
                      ))
                    }
                    <a
                      className='sIconWhats small d-flex'
                      href={sakBaseUrl("/mensagens#tab-extensao")}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ opacity: '.75' }}
                    >
                      <i className='fe fe-plus-circle'/>
                    </a>
                  </>
                )
            }
          </div>
        </div>
      </div>

      {
        previewMsg && (
          <div
            id="simMsg"
            className="printCelular"
            style={{ maxHeight: window.innerHeight - messagesContainerRef.current?.getBoundingClientRect().bottom }}
          >
            <div className="contorno-cel">
              <span></span>
              <span></span>
            </div>
            <div className="header-cel">
              <strong>{ previewMsg.title }</strong>
            </div>
            <div className="msg-cel">{ previewMsg.body }</div>
          </div>
        )
      }
    </>
  );
}