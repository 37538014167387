import { useEffect, useState, useRef } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { classnames, sakBaseUrl, copyToClipBoard, timestampToString } from '../../../../utils/usefulFunctions';
import Tooltip from '../../../Tooltip';

const arrayStatusColor =  {
  'pending': 'warning',
  'paid': 'success',
  'abandoned': 'gray',
  'order_cancelled': 'danger',
  'order_refunded': 'success',
  'lead_convertido': 'success',
  'leads': 'info',
};

const arrayStatus =  {
  'pending': 'Pendente',
  'paid': 'Pago',
  'abandoned': 'Abandonado',
  'order_cancelled': 'Cancelado',
  'order_refunded': 'Reembolso',
  'lead_convertido': 'Lead Convertido',
  'leads': 'Lead',
};

const gatewayImage = {
  'mercado_pago': 'image/gateways/mercadopago.svg',
  'mercado_pago_boleto': 'image/gateways/mercadopago.svg',
  'mercado_pago_cc': 'image/gateways/mercadopago.svg',
  'mercadopago': 'image/gateways/mercadopago.svg',
  'mercado_pago_transparente': 'image/gateways/mercadopago.svg',
  'pag_seguro': 'image/gateways/uol.svg',
  'pagseguro': 'image/gateways/uol.svg',
  'checkout_moip': 'image/gateways/moip.svg',
  'pagbrasil': 'image/gateways/pagbrasil.svg',
  'ebanx': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via': 'image/gateways/ebanx.svg',
  'cartão_de_crédito_e_boleto_via_ebanx': 'image/gateways/ebanx.svg',
  'paypal': 'image/gateways/paypal.svg',
  'guru': 'image/gateways/guru.svg',
  'paghiper': 'image/gateways/paghiper_m.jpg',
  'cielo': 'image/gateways/cielo_m.jpg',
  'desconhecido': 'image/gateways/questionmark.svg',
  'xekoutme': 'image/gateways/xekoutme.svg',
  'cloudfox': 'image/gateways/cloudfox.svg',
  'hotmart': 'image/gateways/hotmart.svg',
  'yampi': 'image/gateways/yampi.svg',
  'appmax': 'image/gateways/appmax.svg',
  'appmax_cc': 'image/gateways/appmax.svg',
  'appmax_boleto': 'image/gateways/appmax.svg',
  'uol': 'image/gateways/uol.svg',
  'kiwi': 'image/gateways/kiwi.svg',
};

const paymentMethodImage = {
  'boleto': 'image/gateways/boleto.svg',
  'pix': 'image/gateways/pix.svg',
  'credit_card': 'image/gateways/credicard.svg',
  'desconhecido': 'image/gateways/desconhecido.svg',
  'paypal': 'image/gateways/paypal.svg'
};

const rastreioNetAttribs = {
  'trackTransportadora': 'Código Transportadora',
  'trackPadrao': 'Código Rastreio',
  'transportadora': 'Transportadora',
  'dataPostagem': 'Data Postagem',
  'dataEntrega': 'Data Entrega',
  'numEmailEnviados': 'E-mails Enviados',
  'ultimoStatus': 'Último Status',
  // 'badge': 'Status'
}

function useExtraInfoPedidoExtensao (order) {
  let queryUrl = null;

  if (order && order.plataforma && order.orderid) {
    const queryParams = new URLSearchParams({
      tokenchat: window.sakChatToken,
      chave_empreender: window.sakChatChave,
      order_id: order.orderid,
      provider: order.plataforma
    });

    queryUrl = sakBaseUrl('/Js/GetExtraInfoPedidoExtensaoV2?') + queryParams.toString();
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 600_000
  });

  let extraInfo = data?.data || {};
  if (extraInfo?.line_items?.length && order?.line_items?.length) {
    extraInfo.line_items = extraInfo.line_items.map(eItem => {
      const oProduct = order.line_items.find(oItem => {
        return oItem.product_id === eItem.product_id || oItem.name === eItem.name;
      });

      if (oProduct)
        eItem.image_urls = oProduct.image_urls;

      return eItem;
    });
  }

  return {
    data: extraInfo,
    isLoading,
    isValidating,
    error,
    mutate
  };
}

export function useMensagensExtensao () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave
  });

  if (window.sakChatIsMeta)
    queryParams.append('official_only', 1);

  const queryUrl = sakBaseUrl('/Js/GetMensagensExtensaoV2?') + queryParams.toString();

  const { data, error, isLoading, mutate } = useSWR(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 60_000
  });

  return {
    data: data?.data || {},
    isLoading,
    error,
    mutate
  };
}

export function enviarMensagemExtensao (msgId, orderId) {
  const formParams = new FormData();

  formParams.append('tokenchat', window.sakChatToken);
  formParams.append('chave_empreender', window.sakChatChave);
  formParams.append('wapp_id', window.sakChatLoja);
  formParams.append('chat_id', window.sakChatContato);
  formParams.append('msg_id', msgId);
  formParams.append('order_id', orderId);

  fetch(sakBaseUrl('/Js/EnviarMensagemExtensaoV2'), {
    method: 'POST',
    body: formParams
  })
    .then(res => res.json())
    .then(data => {
      if (data.status !== 'success')
        return window.notifChat((
          <>
            <strong>Erro ao enviar mensagem</strong>
            <br/>
            <small>{ data.message }</small>
          </>
        ), 'error');

      if (data.data.action === 'send')
        return window.notifChat('Enviando mensagem', '');

      const messageInputEl = document.getElementById('chat-id-1-input');
      if (!messageInputEl) return;

      const valueSetter = Object.getOwnPropertyDescriptor(messageInputEl.constructor.prototype, 'value').set;
      valueSetter.call(messageInputEl, data.data.message);
      messageInputEl.dispatchEvent(new Event('input', { bubbles: true }));
      messageInputEl.focus();
    })
    .catch(_err => {
      window.notifChat((
        <>
          <strong>Erro ao enviar mensagem</strong>
          <br/>
          <small>{ _err }</small>
        </>
      ), 'error');
    });
}

export default function OrdersTab ({
  orders,
  rastreioNet
}) {
  const { t } = useTranslation();

  const [orderIndex, serOrderIndex] = useState(0);
  const [previewMsg, setPreviewMsg] = useState(null);
  const [collapseMessages, setCollapseMessages] = useState(!!window.sakChatIsMeta);
  const messagesContainerRef = useRef(null);

  const { data: messages_data, isLoading: messages_isLoading } = useMensagensExtensao();

  useEffect(() => {
    if (orderIndex > 0 && orderIndex > orders.length - 1)
      serOrderIndex(0);
  }, [orders, orderIndex]);

  const order = orders?.[orderIndex];
  
  const {
    data: extraInfo_data,
    isValidating: extraInfo_isValidating,
    mutate: extraInfo_mutate
  } = useExtraInfoPedidoExtensao(order);

  if (!order) {
    return (
      <div>
        <h6>{ t('Nenhum pedido encontrado') }</h6>
      </div>
    );
  }

  const trackingInfo = rastreioNet?.[order?.orderid]?.[order?.tracking];
  const line_items = extraInfo_data.line_items || order.line_items;
  const currency = extraInfo_data.currency || order.currency;
  const total_price = extraInfo_data.total_price || order.total_price;
  const total_tax = extraInfo_data.total_tax || order.total_tax;
  const total_discount = extraInfo_data.total_discount || order.total_discount;

  return (
    <>
      {
        orders?.length > 1 && (
          <div className="main-nav-items">
            <ul
              className="nav nav-tabs SFnav"
              role="tablist"
            >
              {
                orders.map((order, index) => (
                  <li
                    className="nav-item"
                    key={ 'order_nav_' + (order.id ?? index) }
                  >
                    <button
                      className={"nav-link taborder" + (index === orderIndex ? ' active' : '')}
                      onClick={() => serOrderIndex(index)}
                      role="tab"
                      aria-controls={ order.order_name || '?' }
                      aria-selected={ orderIndex === index }
                    >
                      { order.order_name || '?' }
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }

      <>
        <div className="row mt-3">
          <div className="col-12">
            <div className="order_section d-flex justify-content-between">
              <h4 className="sak--ext-title">
                <i className="fe fe-message-square mr-3"/> { t('Mensagens rápidas') }
              </h4>
              {
                window.sakChatIsMeta && (
                  <span className='d-flex align-items-center'>
                    <i
                      className={classnames('fe', collapseMessages ? 'fe-chevron-down' : 'fe-chevron-up')}
                      onClick={() => setCollapseMessages(!collapseMessages)}
                      style={{ cursor: 'pointer', color: 'var(--basic-inverse)', fontSize: '14px' }}
                    />
                  </span>
                )
              }
            </div>
            <div ref={messagesContainerRef} className="d-flex mt-4 px-3" style={{ flexWrap: 'wrap', gap: '.5rem', alignItems: 'center' }}>
              {
                !collapseMessages && (
                  messages_isLoading
                    ? (
                      <span>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">{t('Carregando...')}</span>
                        </div>
                      </span>)
                    : (
                      <>
                        {
                          messages_data?.mensagens?.map(msg => (
                            <span
                              key={'msg_' + msg.id}
                              className='sIconWhats'
                            >
                              <span
                                className="whatsLink"
                                onMouseOver={() => setPreviewMsg({ body: msg.texto, title: msg.nome })}
                                onMouseOut={() => setPreviewMsg(null)}
                                onClick={() => enviarMensagemExtensao(msg.id, order.id)}
                              >
                                <i className='fab fa-whatsapp'/>
                              </span>
                            </span>
                          ))
                        }
                        <a
                          className='sIconWhats small d-flex'
                          href={sakBaseUrl("/mensagens#tab-extensao")}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ opacity: '.75' }}
                        >
                          <i className='fe fe-plus-circle'/>
                        </a>
                      </>
                    )
                )
              }
            </div>
          </div>
        </div>      
          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between order_section">
                <h4 className="sak--ext-title mb-0">
                  <i className="fe fe-tag mr-3"/> { t('Detalhes do pedido') }
                </h4>
              </div>
            </div>

            <div className="col-12">
              <div className="customers-content">
                <div className="sak--perfil-info pl-3" style={{ overflowWrap: 'anywhere' }}>
                  <ul>
                    <li>
                      <strong>{ t('Pedido') }</strong>
                      <span>
                        {
                          order.link_pedido
                            ? (
                              <a
                                className="sak--ext-sub-title"
                                href={order.link_pedido}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span dangerouslySetInnerHTML={{ __html: order.plataforma}} style={{ textTransform: 'capitalize' }} />: { order.order_name }
                                <Tooltip text={t('Copiar')}>
                                  <i
                                    className='fal fa-copy ml-3'
                                    onClick={_ => copyToClipBoard(order.link_pedido)}
                                  />
                                </Tooltip>
                              </a>
                            )
                            : (
                              <span className="sak--ext-title" style={{ display: 'block' }}>
                                <span dangerouslySetInnerHTML={{ __html: order.plataforma}} style={{ textTransform: 'capitalize' }} />: { order.order_name }
                                <Tooltip text={t('Copiar')}>
                                  <i
                                    className='fal fa-copy ml-3'
                                    onClick={_ => copyToClipBoard(order.order_name)}
                                  />
                                </Tooltip>
                              </span>
                            )
                        }
                      </span>
                    </li>
                    <li>
                      <strong>{ t('Nome') }</strong>
                      <span>{ order.customer?.name }</span>
                      {
                        order.customer?.name && (
                          <Tooltip text={t('Copiar')}>
                            <i
                              className='fal fa-copy ml-3'
                              onClick={_ => copyToClipBoard(order.customer.name)}
                            />
                          </Tooltip>
                        )
                      }
                    </li>
                    <li>
                      <strong>{ t('Data') }</strong>
                      <span>{ timestampToString(order.created_at) }</span>
                      <Tooltip text={t('Copiar')}>
                        <i
                          className='fal fa-copy ml-3'
                          onClick={_ => copyToClipBoard(timestampToString(order.created_at))}
                        />
                      </Tooltip>
                    </li>
                    <li>
                      <strong>{ t('Atualização') }</strong>
                      <span>{ timestampToString(order.updated_at) }</span>
                      <Tooltip text={t('Copiar')}>
                        <i
                          className='fal fa-copy ml-3'
                          onClick={_ => copyToClipBoard(timestampToString(order.updated_at))}
                        />
                      </Tooltip>
                    </li>
                    <li>
                      <strong>{ t('Telefone') }</strong>
                      <span>{ order.customer?.phone }</span>
                      {
                        order.customer?.phone && (
                          <Tooltip text={t('Copiar')}>
                            <i
                              className='fal fa-copy ml-3'
                              onClick={_ => copyToClipBoard(order.customer.phone)}
                            />
                          </Tooltip>
                        )
                      }
                    </li>
                    <li>
                      <strong>{ t('E-mail') }</strong>
                      <span>{ order.customer?.email }</span>
                      {
                        order.customer?.email && (
                          <Tooltip text={t('Copiar')}>
                            <i
                              className='fal fa-copy ml-3'
                              onClick={_ => copyToClipBoard(order.customer.email)}
                            />
                          </Tooltip>
                        )
                      }
                    </li>
                    <li>
                      <strong>{ t('Endereço') }</strong>
                      <span>{ order.customer?.formatted_address }</span>
                      {
                        order.customer?.formatted_address && (
                          <Tooltip text={t('Copiar')}>
                            <i
                              className='fal fa-copy ml-3'
                              onClick={_ => copyToClipBoard(order.customer.formatted_address)}
                            />
                          </Tooltip>
                        )
                      }
                    </li>
                  </ul>
                </div>
      
                <div className="customers-item d-flex mt-4 px-3" style={{ gap: '.5rem', flexWrap: 'wrap' }}>
                  <h4 className="sak--ext-title mb-2">{ t('Status de pagamento') }</h4>
                  <span
                    className={"badge badge-pill badge-soft-" + arrayStatusColor[order.status_pagamento]}
                  >
                    { t(arrayStatus[order.status_pagamento]) }
                  </span>

                  {
                    order.checkout_url && order.status_pagamento === 'abandoned' && (
                      <a href={order.checkout_url} target="_blank" rel='noreferrer'>
                        <img src={sakBaseUrl('image/gateways/cart.svg')} width='38' alt='cart'/>
                      </a>
                    )
                  }

                  {
                    order.gateway && (
                      <span className='link-payments mr-1' title={order.gateway}>
                        <img
                          width='38'
                          className='logo-payments'
                          src={sakBaseUrl(gatewayImage[order.gateway] || gatewayImage['desconhecido'])}
                          alt={ t(order.gateway) }
                        />
                      </span>
                    )
                  }

                  {
                    order.forma_pagamento && (
                      order.forma_pagamento === 'boleto'
                        ? (
                          <>
                            <a className='link-payments mr-1' href={order.link_boleto} target='_blank' rel='noreferrer'>
                              <img
                                width='38'
                                className='logo-payments'
                                src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                                alt={ t(order.forma_pagamento) }
                              />
                              <Tooltip text={t('Copiar')}>
                                <i
                                  className='fal fa-copy ml-3'
                                  onClick={e => {
                                    e.preventDefault();
                                    copyToClipBoard(order.link_boleto);
                                  }}
                                />
                              </Tooltip>
                            </a>
                          </>
                        )
                        : (
                          <span className='link-payments mr-1'>
                            <Tooltip text={ t(order.forma_pagamento) }>
                              <img
                                width='38'
                                className='logo-payments'
                                src={sakBaseUrl(paymentMethodImage[order.forma_pagamento] || paymentMethodImage['desconhecido'])}
                                alt={ t(order.forma_pagamento) }
                              />
                            </Tooltip>
                          </span>
                        )
                    )
                  }
                </div>
      
                <div className="customers-item products-list mt-5">
                  <div className="order_section mb-2 d-flex justify-content-between">
                    <h4 className="sak--ext-title">
                      <i className="fe fe-package mr-3"/> { t('Produtos') }
                    </h4>
                    <span className='d-flex align-items-center'>
                      <Tooltip text={t('Recarregar')}>
                        <i
                          className={classnames('fe fe-refresh-cw', { 'spin-animation': extraInfo_isValidating })}
                          onClick={() => !extraInfo_isValidating && extraInfo_mutate()}
                          style={{ cursor: 'pointer', color: 'var(--basic-inverse)', fontSize: '14px' }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <ul className="ul-product px-3 mt-4">
                    {
                      line_items?.length > 0
                        ? line_items?.map((item, pIndex) => (
                          <li
                            className="li-product"
                            key={ 'product_' + (item.product_id ?? pIndex) }
                          >
                            <div className="li-product-info row align-items-center">
                              <div className="foto-prod">

                                {
                                  item.image_urls?.map((img, iIndex) => {
                                    return (
                                      <img
                                        className="mr-2 imgProd"
                                        key={ 'product_' + (item.product_id ?? pIndex) + '_' + iIndex }
                                        src={img}
                                        onError={(e) => e.target.src = sakBaseUrl('/image/sem-imagem.svg')}
                                        alt="produto_img"
                                      />
                                    )
                                  })
                                }

                              </div>

                              <div className="product-info detalhe-prod">
                                <h5 className="font-size-md mb-0">
                                  { item.name || item.title }
                                </h5>

                                <small>
                                  <strong>
                                    {
                                      item.variant_title && (
                                        <div>
                                          { t('Variante') }: { item.variant_title }
                                        </div>
                                      )
                                    }

                                    {
                                      (item.price > 0 || item.quantity > 0) && (
                                        <div>
                                          { item.quantity > 0 && (item.quantity + ' un ') }
                                          { item.price > 0 && (currency + ' ' + item.price) }
                                        </div>
                                      )
                                    }

                                    {
                                      total_discount > 0 && (
                                        <div>
                                          { t('Desconto') }: { currency } { total_discount }
                                        </div>
                                      )
                                    }
                                  </strong>
                                </small>
                              </div>
                            </div>
                          </li>
                        ))
                        : (
                          <span>{ t('Nenhum produto associado a este pedido') }</span>
                        )
                    }
                  </ul>
                  <div className="sak--perfil-info px-3">
                    <ul>
                      <li>
                        <strong>{ t('Frete') }</strong>
                        {
                          total_tax >= 0
                            ? `${currency} ${total_tax}`
                            : t('Indisponível')
                        }
                      </li>
                      <li>
                        <strong>{ t('Total') }</strong>
                        { currency } { total_price }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="row mt-3 mb-4">
            <div className="col-12 order_section mb-2">
              {
                order.tracking
                  ? (
                    <a
                      className="sak--ext-title link d-inline-flex"
                      href={'https://rastreio.net/' + order.tracking.split(',')[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fe fe-truck mr-3"/> Rastreio.net
                    </a>
                  )
                  : (
                    <span
                      className="sak--ext-title link d-inline-flex"
                    >
                      <i className="fe fe-truck mr-3"/> Rastreio.net
                    </span>
                  )
              }
              
            </div>
      
            <div className="col-12 mt-4 px-3">
              <div className="rastreio-content">
                <div className="rastreio-item">
                  <div className="rastreio-info">
                    <div className="rastreio-number">
                      {
                        order.status_pagamento === 'paid' && trackingInfo
                          ? (
                            <div>
                              {
                                Object.entries(trackingInfo).map(([aName, aValue]) => {
                                  if (!rastreioNetAttribs[aName]) return null;

                                  return (
                                    <small
                                      className="form-text text-muted"
                                    >
                                      <strong>{ t(rastreioNetAttribs[aName]) }:</strong> { aValue }
                                    </small>
                                  )
                                })
                              }
                            </div>
                          )
                          : (
                            order?.tracking
                              ? (
                                <div style={{ fontSize: '14px', color: '#828bab' }}>
                                  <strong style={{ fontWeight: '500', color: 'var(--basic-inverse)' }}>{ t('Código') }:</strong> { order.tracking.split(',').map(tracking => tracking.trim()).join(', ') }
                                </div>
                              )
                              : (
                                <span>{ t('Sem informações de rastreio associadas a este pedido') }</span>
                              )
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            previewMsg && (
              <div
                id="simMsg"
                className="printCelular"
                style={{ maxHeight: window.innerHeight - messagesContainerRef.current?.getBoundingClientRect().bottom }}
              >
                <div className="contorno-cel">
                  <span></span>
                  <span></span>
                </div>
                <div className="header-cel">
                  <strong>{ previewMsg.title }</strong>
                </div>
                <div className="msg-cel">{ previewMsg.body }</div>
              </div>
            )
          }
      </>
    </>
  );
}