import styled from 'styled-components';
import { useMemo, useEffect, useState } from 'react';

const StyledWrapper = styled.div`
  position: sticky;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;

  @media (min-width: 1200px) {
    margin-bottom: 8px;
  }

  .pagination-container {
    padding-inline-start: 0;
    display: flex;
    list-style-type: none;
    background-color: var(--secondary);
    border-radius: 16px;
    padding: 8px 16px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); 

    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(var(--basic-inverse), .87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        font-weight: 600;
        color: rgba(var(--basic-inverse), 1);
        background-color: rgba(0, 0, 0, 0.08);
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
`;

const DOTS = '...';
const range = (start, end) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const classnames = (...classes) => {
  return classes.map(className => {
    if (typeof className === 'object') {
      return Object.entries(className)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
    }

    return className;
  }).filter(Boolean).join(' ');
};

export function useWindowDimensions() {
  function getWindowDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount)
      return range(1, totalPageCount);

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2)
    return null;

  const onNext = () => {
    onPageChange(Math.min(currentPage + 1, Math.ceil(totalCount / pageSize)));
  };

  const onPrevious = () => {
    onPageChange(Math.max(currentPage - 1, 1));
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <i className="fe-arrow-left"></i>
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS)
          return <li className="pagination-item dots">&#8230;</li>;

        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <i className="fe-arrow-right"></i>
      </li>
    </ul>
  );
};

export const ContatosPagination = ({
  contatosPage,
  setContatosPage,
  totalCount,
  paginationQuantity,
}) => {
  let siblingCount = 1;
  const windowDimensions = useWindowDimensions();
  if (windowDimensions.width >= 1200)
    siblingCount -= 1;

  if (totalCount <= paginationQuantity) return null;

  return (
    <StyledWrapper>
      <Pagination
        currentPage={contatosPage + 1}
        totalCount={totalCount}
        siblingCount={siblingCount}
        pageSize={paginationQuantity}
        onPageChange={(page) => setContatosPage(page - 1)}
        />
    </StyledWrapper>
  );
};
