import { Wrapper } from './styles';
// import { useSocket } from '../../../../hooks/socket';
import useSocketStore from '../../../../stores/useSocketStore';
import { useTranslation } from 'react-i18next';

const ModalDeleteMessage = ({ id, mensagens }) => {
  // const { sendSocketDados } = useSocket();
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const { t, i18n } = useTranslation();

  function apagarMensagem(idMensagem) {
    const mensagem = mensagens[idMensagem];
    const { idFull, userserv, userchat } = mensagem || {};

    if (
      !sendSocketDados({
        function: 'excluirMensagem',
        data: [{ idFull, userserv, userchat }],
      })
    );

    const $ = window.jQuery;
    const container = $($(`#modalDeleteMessage-${id}`)[0]);
    container.modal('hide');
  }

  return (
    <Wrapper>
      <div
        className="modal fade modalDeleteMessage"
        id={`modalDeleteMessage-${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalDeleteMessageLabel-${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5>{t('Deseja apagar a mensagem?')}</h5>
            </div>
            <div className="modal-footer" style={{ borderTop: 'none' }}>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t('Cancelar')}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => apagarMensagem(id)}
              >
                {t('Apagar para todos')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ModalDeleteMessage;
