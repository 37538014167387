export function openDark() {
  let darkMode = localStorage.getItem('darkMode');

  if (darkMode === null && window.matchMedia('(prefers-color-scheme: dark)').matches)
    localStorage.setItem('darkMode', darkMode = 'ativo');

  const selectDark = document.querySelector('#darkmode');
  if (!selectDark) return null;

  const alterarModo = (modo, texto) => {
    document.body.classList.toggle('darkmode');
    localStorage.setItem('darkMode', modo);
    selectDark.children[0].innerText = texto;
    if (selectDark.children[0].innerText === 'undefined') {
      darkMode === 'ativo'
        ? (selectDark.children[0].innerText = 'Desativar')
        : (selectDark.children[0].innerText = 'Ativar');
    }
  };

  darkMode === 'ativo' && alterarModo('ativo', 'Desativar');

  selectDark.addEventListener('click', () => {
    darkMode = localStorage.getItem('darkMode');
    darkMode !== 'ativo'
      ? alterarModo('ativo', 'Desativar')
      : alterarModo(null, 'Ativar');
  });
}
