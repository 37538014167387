import { useRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import useSocketStore from '../../../../stores/useSocketStore';
import { TextArea } from '../../../CaixaMensagem/styles';
import { formatTimeMensagens } from '../../../../utils/formatDados';

const ModalEditMessage = ({ id, mensagens }) => {
  const { t } = useTranslation();
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const inputTextRef = useRef(null);

  const mensagem = mensagens[id];
  // const mensagem = useMemo(() => mensagens[id], [id, mensagens, ]);
  const [canEdit, setCanEdit] = useState(false);

  function editarMensagem(idMensagem) {
    if (!canEdit) return;

    const mensagem = mensagens[idMensagem];
    const { idFull, userserv, userchat } = mensagem || {};

    const texto = inputTextRef.current.value;

    if (
      !sendSocketDados({
        function: 'editarMensagem',
        data: [{ idFull, userserv, userchat, texto }],
      })
    );

    const $ = window.jQuery;
    const container = $($(`#modalEditMessage-${id}`)[0]);
    container.modal('hide');
  }

  function filtroMensagem(texto) {
    const regexLinkLily = /https:\/\/aval\.ie\/\w{8,}/i;
    const regexpEncerrarAtendimento = /\*#Robô:\* Atendimento encerrado./gi;

    if (texto.match(regexpEncerrarAtendimento))
      return '*#Robô:* Atendimento encerrado';

    if (texto.includes('https://aval.ie/') && !regexLinkLily.test(texto))
      return 'Atendimento finalizado!';

    return texto;
  }

  const handleTextSend = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      editarMensagem(id);
    }
  };

  const handleResize = (event) => {
    const inputEl = inputTextRef.current;

    const sizeLength = 7; // quantidade px de cada letra
    const textLength = inputEl?.value?.length || 0; // quantidade de carater
    const baseWidth = 141; // é o tamanho mínimo do bloco de texto
    const lengthWidth = baseWidth + textLength * sizeLength; // tamanho do container com caracteres
    const containerWidth = inputEl.offsetWidth;
    const calcLines = ~~(lengthWidth / containerWidth);

    const numeroDeLinhas = (inputEl.value?.match(/\n/g) || [])?.length;
    const defaultHeight = 30 + (calcLines + numeroDeLinhas) * 18 + 12 + 2;
    inputEl.style.height = `${defaultHeight}px`;
  };

  useEffect(() => {
    if (!mensagem) return;

    const novoTexto = filtroMensagem(mensagem.texto);
    inputTextRef.current.value = novoTexto.replace(/\\n/, '\n');

    handleResize();

    // Gambiarra status mensagem
    try {
      const $ = window.jQuery;
      const statusSrc = $(`[data-id="${mensagem.id}"] .metadata img`).last().attr('src');
      if (statusSrc)
        $(`#modalEditMessage-${mensagem.id} .metadata img`).attr('src', statusSrc);
    } catch {}

    // Se o texto foi modificado, não pode editar
    // Tem uma exceção para a mensagem de atendimento finalizado
    if (mensagem.texto !== novoTexto || novoTexto === 'Atendimento finalizado!') {
      setCanEdit(false);
      return;
    }

    // Se a mensagem houver sido enviada há mais de 15 minutos, não pode editar
    const elapsed = ~~(Date.now() / 1000) - mensagem.data_whats;
    setCanEdit(elapsed < 900);
  }, [mensagem]);

  return (
    <Wrapper>
      <div
        className="modal fade modalEditMessage"
        id={`modalEditMessage-${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalEditMessageLabel-${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center" style={{ borderBottom: 'none' }}>
              <h5>{t('Editar mensagem')}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="message-body pb-7 pt-4">
                <div className="message-row">
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ position: 'relative' }}
                  >
                    <div
                      style={{ order: 1 }}
                      className="message-image-content bg-convs text-dark position-relative"
                    >
                      <div className="d-flex position-relative flex-wrap justify-content-end">
                        {filtroMensagem(mensagem.texto)}
                        <div className="metadata">
                          {formatTimeMensagens(mensagem.data_whats)}
                          <img
                            className="ml-2 mt-1"
                            alt='status'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
            <div className="modal-footer" style={{ borderTop: 'none', background: 'var(--secondary)' }}>
              <div className="d-flex align-items-center w-100">
                <TextArea
                  ref={inputTextRef}
                  id="chat-id-1-edit-input"
                  onKeyPress={handleTextSend}
                  onChange={handleResize}
                  placeholder={t('Digite aqui a sua mensagem.')}
                  data-autosize="true"
                ></TextArea>
                <button
                  className={
                    canEdit
                      ? "btn py-0"
                      : "btn py-0 disabled cursor-none opacity-20"
                    }
                  style={{ fontSize: '1.45rem' }}
                  onClick={() => canEdit && editarMensagem(id)}
                  title={t('Editar mensagem')}
                >
                  <span className="fe-edit" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ModalEditMessage;
