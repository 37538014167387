import { useState, useEffect, memo } from 'react';

export default memo(function Avatar({ dados }) {
  const formatConversaID = dados
    ? dados.conversa
      ? String(dados.conversa.split('@')[0]).slice(-2)
      : dados.id
      ? String(dados.id.split('@')[0]).slice(-2)
      : dados.inclusao
      ? String(dados.inclusao).slice(-2)
      : '1'
    : '1';

  const avatarIcon = `${process.env.PUBLIC_URL}assets/avatar/${formatConversaID}.svg`;

  const [imgSrc, setImgSrc] = useState(avatarIcon);

  const onError = () => setImgSrc(avatarIcon);

  useEffect(() => {
    if (dados && dados['photo']) {
      setImgSrc(dados['photo']);
      // eslint-disable-next-line eqeqeq
    } else if (dados == '-1')
      setImgSrc(`${process.env.PUBLIC_URL}assets/robot.svg`);
    else setImgSrc(avatarIcon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={imgSrc}
      style={{ width: '35px', height: '35px' }}
      onError={onError}
      loading="lazy"
    />
  );
});
