export function throttle(func, wait) {
  let lastCallTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastCallTime >= wait) {
      func.apply(this, args);
      lastCallTime = now;
    }
  };
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('inicializacao'))['token'];

export const classnames = (...classes) => {
  return classes.map(className => {
    if (typeof className === 'object') {
      return Object.entries(className)
        .filter(([_, value]) => value)
        .map(([key, _]) => key)
        .join(' ');
    }

    return className;
  }).filter(Boolean).join(' ');
};

export const sakBaseUrl = (...paths) => {
  if (paths[0].startsWith('/'))
    paths[0] = paths[0].slice(1);

  return 'https://app.sak.com.br/' + paths.join('/');
};

export const copyToClipBoard = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export const timestampToString = (timestamp) => {
  return new Date(parseInt(timestamp) * 1e3).toLocaleString();
}
