import React, { useEffect } from 'react';

import {
  useLojas,
  // useAtendente,
  // useContatos,
  // useMensagens,
  useStatusApp,
  useUserLogado,
} from './hooks/dadosSak';
import useDadosSakStore from './stores/useDadosSakStore';

import { Loading } from './styles/GlobalStyles';
import { t } from 'i18next';

export default function LoadApp({ children }) {
  // const { userLogado } = useUserLogado();
  // const { mensagens } = useMensagens();
  // const { contatos } = useContatos();
  // const { userChat } = useAtendente();
  // const { lojas } = useLojas();
  // const { statusApp } = useStatusApp();

  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojas = useDadosSakStore((state) => state.lojas);
  const statusApp = useDadosSakStore((state) => state.statusApp);

  useEffect(() => {
    if (statusApp === 1) {
      const msgRandom = document.querySelector('#msgRandom h1');
      const msgRandomH3 = document.querySelector('#msgRandom h3');
      let msgAleatoria;
      if (msgRandom && msgRandomH3) {
        fetch('piadas.json')
          .then((r) => r.json())
          .then((dados) => {
            msgAleatoria = dados[~~(Math.random() * dados.length)];

            msgRandom.innerHTML = msgAleatoria.pergunta;
            msgRandomH3.innerHTML = msgAleatoria.resposta;
          });
      }
    }
  }, [statusApp]);

  function carregando() {
    if (statusApp === 1) {
      return (
        <div
          id="msgRandom"
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100vh', backgroundColor: '#fbfbfb' }}
        >
          <object type="image/gif" data="assets/loading.gif" width="75">
            <Loading />
          </object>
          <h2 className="pt-3">{t('Carregando...')}</h2>
          {/* <h3> </h3> DESCOMENTAR PARA APARECER AS PIADAS NO LOADING */}
        </div>
      );
    } else if (statusApp === 2) {
      return (
        <div
          id="msgRandom"
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <div className="reload-app">
            <h2>
              {t(
                'o Chat do Sak foi aberto em outra janela do navegador! Clique em "usar aqui" para voltar a utilizar nesta janela.'
              )}
            </h2>
            <div className="d-flex align-items-center justify-content-end">
              <button
                onClick={() =>
                  (window.location.href = 'https://app.sak.com.br/dashboard')
                }
              >
                {t('Sair')}
              </button>
              <button onClick={() => window.location.reload()}>
                {t('Usar aqui')}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="msgRandom"
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <img
            className="ml-2 mt-1"
            width="75"
            src={`${process.env.PUBLIC_URL}assets/logo.svg`}
            alt="sak"
          />
          <h2 className="text-center">
            {t('Sua sessão foi expirada, acesse o chat novamente clicando')}{' '}
            <a href="https://app.sak.com.br/dashboard"> {t('aqui')}</a>
          </h2>
        </div>
      );
    }
  }

  if (
    // contatos &&
    // Object.values(contatos).length !== 0 &&
    // mensagens &&
    // Object.values(mensagens).length !== 0 &&
    // userChat &&
    // Object.values(userChat).length !== 0 &&
    lojas &&
    Object.values(lojas).length !== 0 &&
    userLogado &&
    userLogado.sessao !== 'existente'
  ) {
    return children;
  } else {
    return carregando();
  }
}
