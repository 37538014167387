import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  background: transparent;
  padding: 0 20px;
  overflow-x: auto;
  position: relative;
  box-shadow: 0px 0px 25px 25px var(--light);
  z-index: 0;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7e8896;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scroll);
    border-radius: 20px;
  }

  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1366px) {
    ::-webkit-scrollbar {
      height: 8px;
    }
  }
`;

export const ContainerLoja = styled.div`
  height: 100%;
  margin-right: 20px;
  padding: 10px 0;
`;

export const Icon = styled.div`
  background: ${(props) => (props.color ? props.color : '#ccc')};
  color: white;
  border-radius: 3px;
  position: relative;
  width: 26px;
  height: 26px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${(props) => (props.color ? darken(0.3, props.color) : '#ccc')};
  }

  &:before {
    content: '';
    display: ${(props) =>
      props.select && props.select === 'selected' ? 'inline' : 'none'};
    position: absolute;
    height: 2px;
    left: 0;
    width: 100%;
    top: calc(100% + 8px);
    background: var(--azul);
  }

  &:after {
    background-color: #ff5848;
    width: 20px;
    height: 20px;

    position: absolute;
    top: -9px;
    right: -9px;
    font-size: 11px;

    border-radius: 12px;
    border: 1px solid white;

    font-weight: bold;
    color: white;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    content: '${(props) => (props.notif > 99 ? '99+' : props.notif)}';
    display: ${(props) => (props.notif && props.notif > 0 ? 'inline' : 'none')};
  }
`;
